/* eslint-disable import/extensions */
import Vue from "vue";
import Vuex from "vuex";

// Modules
import versionManager from "@/services/versionManager/_store";
import login from "@/modules/login/_store/index.extranet";
import appInfos from "@/services/appInfos/_store";
import ordoclic from "@/services/ordoclic/_store";
import register from "@/modules/register/_store/index.extranet";
import dashboard from "@/modules/dashboard/_store/index.extranet";
import payment from "@/modules/payment/_store";
import credentials from "@/modules/credentials/_store";
import patientView from "@/modules/patient/view/_store";
import conversationsList from "@/modules/conversations/list/_store";
import conversationsAdd from "@/modules/conversations/add/_store/index.extranet";
import conversationsView from "@/modules/conversations/view/_store";
import patientOrders from "@/modules/patient-orders/_store";

import common from "@/modules/common/_store";
import list from "@/mixins/list/_store";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    versionManager,
    payment,
    credentials,
    login,
    register,
    ordoclic,
    appInfos,
    conversationsList,
    conversationsView,
    conversationsAdd,
    dashboard,
    common,
    list,
    patientView,
    patientOrders
  }
});
